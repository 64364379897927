<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <img src="@/assets/username.png" class="icon" />
            <input type="text" v-model="username" class="input" placeholder="请输入姓名" />
          </div>
          <div class="item">
            <img src="@/assets/tel.png" class="icon" />
            <input type="text" v-model="tel" class="input" placeholder="请输入手机号码" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      username: '',
      tel: ''
    };
  },
  methods: {
    async submit() {
      if (isEmpty(this.username)) {
        Toast('用户名不可以为空!');
        return;
      }
      if (!isTel(this.tel)) {
        Toast('电话号格式不正确!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/tlService/addUserMW',
        qs.stringify({ username: this.username, usertel: this.tel })
      );
      if (data.flag === '0') {
        Toast('提交成功!');
        this.$router.replace('/user/my');
      } else {
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
</style>